import { Route, Routes } from "react-router-dom";
import Articulos from "./components/Articulos/Articulos";
import Login from "./components/Login/Login";
import Layout from "./components/Layout/Layout";


function App() {

  return (
    <>

      <Routes>

        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        <Route path="/layout" element={<Layout />}>
          {/* <Route index element={<Articulos />} /> */}
          <Route path="articulos" element={<Articulos />} />
        </Route>

      </Routes>

    </>
  );
}

export default App;
