import eeuu from '../../images/eeuu.png'
import arg from '../../images/argentina.png'
import iconoStock from '../../images/stockDisponible.png'


export default function ModalArticulo({ selectedArticulo, closeModal }) {

    const formatPrice = (price) => {
        const parts = price?.split('.');
        if (parts?.length > 1) {
            parts[1] = parts[1]?.substring(0, 2);
            return parts?.join('.');
        }
        return price;
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-80 flex justify-center items-center z-50">
            <div className="bg-white p-6 m-1 rounded-sm shadow-lg w-full lg:max-w-lg border border-black relative">
                <div className='flex justify-center items-center mb-0 -mt-5'>
                    <p className='text-center text-md font-semibold'>Precio actualizado al: {selectedArticulo?.fupcosto}</p>
                </div>
                <div className="flex justify-center items-center">
                    <img
                        src={selectedArticulo?.Monedadolar.trim() == '1' ? eeuu : arg}
                        alt="bandera"
                        className="w-6 h-6 mr-4"
                    />
                    <h2 className="text-lg text-center font-bold mr-4">{selectedArticulo?.Codigo}</h2>
                    {selectedArticulo?.Stockactual?.trim() !== '0' && (
                        <img src={iconoStock} alt="stock" className='w-7 h-6' />
                    )}

                </div>

                <h2 className="text-lg text-center font-bold text-blue-700">
                    {selectedArticulo?.Descripcion.trim()}
                </h2>

                <hr className="mb-0.5 border border-gray-300" />
                <p className='text-center font-semibold'>STOCK: {selectedArticulo?.Stockactual}</p>
                <hr className="mt-0.5 border border-gray-300" />
                

                <div className="flex justify-between items-center mt-3 -m-2 mb-10 ">
                    <div className="self-start text-left">
                        <p className='font-semibold text-md text-red-600'>PRECIO FINAL</p>
                        <p className="text-md ">
                            <strong className="font-black">PvtaP1 :</strong> {parseFloat(selectedArticulo?.Prevtapub1)?.toFixed(2)}
                        </p>
                        <p className="text-md ">
                            <strong className="font-black">PvtaP2 :</strong> {parseFloat(selectedArticulo?.Prevtapub2)?.toFixed(2)}
                        </p>
                        <p className="text-md ">
                            <strong className="font-black">PvtaP3 :</strong> {parseFloat(selectedArticulo?.Prevtapub3)?.toFixed(2)}
                        </p>
                    </div>
                    <div className="self-end text-left">
                    <p className='font-semibold text-md text-red-600'>PRECIO SIN IVA</p>
                        <p className="text-md">
                            <strong className="font-black">PvtaP1 :</strong> {(parseFloat(selectedArticulo?.Prevtapub1) / ((selectedArticulo?.Alicuota / 100) + 1))?.toFixed(2)}
                        </p>
                        <p className="text-md">
                            <strong className="font-black">PvtaP2 :</strong> {(parseFloat(selectedArticulo?.Prevtapub2) / ((selectedArticulo?.Alicuota / 100) + 1))?.toFixed(2)}
                        </p>
                        <p className="text-md">
                            <strong className="font-black">PvtaP3 :</strong> {(parseFloat(selectedArticulo?.Prevtapub3) / ((selectedArticulo?.Alicuota / 100) + 1))?.toFixed(2)}
                        </p>
                    </div>
                </div>

                <div className="absolute bottom-0 left-0 w-full ">
                    <button
                        className="px-4 py-2 bg-red-500 text-white font-semibold w-full "
                        onClick={closeModal}
                    >
                        CERRAR
                    </button>
                </div>
            </div>
        </div>

    )
}
